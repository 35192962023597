const DebugPage = () => import('../../components/pages/DebugPage.vue');
const QuickStartPage = () => import('../../components/pages/QuickStartPage.vue');
const DatasetsPage = () => import('../../components/pages/DatasetsPage.vue');
const NotFound = { template: "<div>Not found</div>" };

export default [
	{ path: '/', name: 'home', component: NotFound },
	{ path: '/new', name: "new", component: QuickStartPage },
	{ path: '/cleanup', name: "cleanup", component: DatasetsPage },
	{ path: '/ping', name: "debug", component: DebugPage },
	{ path: "*", component: NotFound }
]